import React, { useCallback, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const NotificationBlock = ({ children, type }) => {
  const showNotification = useCallback(() => {
    switch (type) {
      case 'success':
        toast.success(children);
        break;
      case 'error':
        toast.error(children);
        break;
      case 'info':
        toast.info(children);
        break;
      case 'warning':
        toast.warn(children);
        break;
      default:
        toast(children);
    }
  }, [children, type])

  useEffect(() => {
    showNotification();
  }, [children, showNotification, type]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={44000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};
